html {
  width: 100%;
  height: 100%;
  margin-bottom: 60px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.body-container {
  width: 100%;
  height: 100%;
  padding-top: 1px;
  padding-bottom: 1px;
}

.body {
  width: 1300px;
  max-width: 85%;
  margin: auto;
  width: 50%;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}

.light-body {
  background-color: white;
  color: black;
}

.dark-body {
  background-color: #121212;
  color: white;
}

#h1-title {
  width: 100%;
  text-align: center;
  font-size: 50px;
  margin-bottom: 15px;
}

.tagline-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 18px;
  margin-top: -10px;
}

.toggle-button {
  display: block;
  font-size: 12px;
  padding: 0;
  margin: 0;
  overflow: hidden;
  white-space: normal;
  border-radius: 0;
  border: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
}

.toggle-button-dark {
  background-color: rgba(128, 128, 128, 0.068);
  color: black;
}

.toggle-button-dark:hover {
  background-color: rgba(128, 128, 128, 0.548);
}

.toggle-button-dark:active {
  background-color: rgba(128, 128, 128, 0.8);
}

.toggle-button-light {
  background-color: #414141;
  color: white;
}

.toggle-button-light:hover {
  background-color: #292929;
}

.toggle-button-light:active {
  background-color: #1b1b1b;
}

#toggle-placeholder,
.toggle-button {
  width: 160px;
}

.tagline {
  width: 100%;
  text-align: center;
  padding: 0;
  margin: 0;
}

img {
  width: 100%;
  height: auto;
}

#photo {
  order: 1;
  width: 49%;
  height: auto;
}

#content {
  order: 2;
  width: 50%;
  height: auto;
  align-items: flex-start;
}

#image {
  width: 50%;
}

#top {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  width: 100%;
  height: auto;
}

a {
  color: #007acc;
  text-decoration: none;
}

.nav-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0;
}

.nav-container-dark {
  border-bottom: 1px solid black;
}

.nav-container-light {
  border-bottom: 1px solid white;
}

.active-nav-link {
  box-sizing: border-box;
  border-bottom-style: solid;
  border-bottom-width: 2px;
}

.active-nav-link-dark {
  background-color: rgba(128, 128, 128, 0.068);
}

.active-nav-link-light {
  background-color: #414141;
}

.nav-link {
  height: 2rem;
  width: calc(100% / 4);
  padding: auto;
  line-height: 2rem;
  font-size: 0.8rem;
  text-align: center;
  box-sizing: border-box;
  text-decoration: none;
  -webkit-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

.nav-link-dark {
  color: black;
}

.nav-link-light {
  color: white;
}

.nav-link-dark:hover {
  color: black;
  border-bottom-color: black;
  background-color: rgba(128, 128, 128, 0.068);
}

.nav-link-light:hover {
  color: white;
  border-bottom-color: white;
  background-color: #414141;
}

.nav-link:hover {
  box-sizing: border-box;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  -webkit-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  -webkit-transition: opacity 250ms ease-in;
  -moz-transition: opacity 250ms ease-in;
  -o-transition: opacity 250ms ease-in;
  transition: opacity 250ms ease-in;
}

@media only screen and (max-width: 800px) {
  #top {
    flex-wrap: wrap;
  }
  #image {
    width: 100%;
  }
  #content {
    width: 100%;
  }
  .body {
    width: 85%;
  }
}
